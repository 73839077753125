import React, { Component } from "react"

import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"

import imgnppconnectssh from "../../images/guide/nppconnectssh.png"
import imgnppplugin from "../../images/guide/nppplugin.png"
import imgnppshowftp from "../../images/guide/nppshowftp.png"
import imgnppprofile from "../../images/guide/nppprofile.png"
import imgnppnewprofile from "../../images/guide/nppnewprofile.png"
import imgnppnewprofileserver from "../../images/guide/nppnewprofileserver.png"
import imgnppnewprofileauth from "../../images/guide/nppnewprofileauth.png"



export default class SshNotepadPPIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='guide' fullPath='guide/ssh-notepad-pplus' pageTitle="SSH Using Notepad++" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						Edit remote ssh files via Notepad++ Text Editor (Windows user only).
						<h2>Usage</h2>
						<ol>
							<li>From the NppFTP panel, click the left-most icon at the top, then click the new Profile</li>
							<img alt="" src={imgnppconnectssh} />
							<li>Click "Yes" when asked</li>
							<li>The NppFTP panel should list the content of the ssh server</li>
							<li>Double-click on the file(s) to edit.  Saving the file(s) should update the file at the remote server</li>
							<li>From the NppFTP panel, click the left-most icon at the top to Disconnect</li>
						</ol>
						<h2>Configuration</h2>
						One-time configuration to edit files/folders at the SSH server.  
						<ol>
							<li>Download and install appropriate <a className={"visiblelink"} rel="noopener noreferrer" href='https://notepad-plus-plus.org/downloads/' target='_blank'>Notepad++</a></li>
							<li>Start Notepad++</li>
							<li>Install NppFTP plugin</li>
							<ol>
								<li>Go to "Plugins&nbsp;&gt;&nbsp;Plugins&nbsp;Admin..."</li>
								<img alt="" src={imgnppplugin} />
								<li>Search for NppFTP and Check it</li>
								<li>Click Install on the upper-right corner</li>
							</ol>
							<li>Go to "Plugins&nbsp;&gt;&nbsp;NppFTP&nbsp;&gt;&nbsp;Show&nbsp;NppFTP&nbsp;Window"</li>
							<img alt="" src={imgnppshowftp} />
							<li>Click the Settings Icon the NppFTP Panel, then "Profile&nbsp;Settings"</li>
							<img alt="" src={imgnppprofile} />
							<li>Click the "Add&nbsp;New" from the lower left corner, and provide an appropriate name</li>
							<img alt="" src={imgnppnewprofile} />
							<li>Configure the Profile
								<ul>
									<li><strong>Hostname</strong>: provided</li>
									<li><strong>Connection Type</strong>: SFTP</li>
									<li><strong>Username</strong>: provided</li>
									<img alt="" src={imgnppnewprofileserver} />
									<li>Click "Authentication" Tab</li>
									<li>Check <span class='iamnet-copyline'>Try private key file authentication</span> ONLY</li>
									<img alt="" src={imgnppnewprofileauth} />
									<li>Click "..." under Private key file, then open the PEM file provided</li>
									<li>Click "Close" on the lower right corner</li>
								</ul>
							</li>
							<li>From the NppFTP panel, click the left-most icon at the top, then click the new Profile</li>
							<img alt="" src={imgnppconnectssh} />
							<li>Click "Yes" when asked</li>
							<li>The NppFTP panel should list the content of the ssh server</li>
							<li>From the NppFTP panel, click the left-most icon at the top to Disconnect</li>
						</ol>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



